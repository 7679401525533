module services {
    export module applicationcore {
        export class incotermService implements interfaces.applicationcore.IIncotermService {
            static $inject = ["$resource", "ENV"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig) {
            }

            getDropdownList(): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {

                return this.$resource<interfaces.applicationcore.IDropdownModel>(this.ENV.DSP_URL + "Incoterm/GetDropdownList", {
                }, {
                        query: {
                            method: 'GET',
                            isArray: true
                        }
                    });
            }
        }
    }
    angular.module("app").service("incotermService", services.applicationcore.incotermService);
}